@import '../../../../styles/variables';
@import '../../../../styles/mixins/3d-block';

.NavigationLink {

  &__is-active {
    button {
      @include elevate-3D-block-grounded;
      box-shadow: $grounded-block-glow-box-shadow-1;
      @include is-neutral-active;
    }
  }

}
