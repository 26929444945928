@import '../../styles/variables';
@import '../../styles/mixins/medias';
@import '../../styles/mixins/hint-text';
@import '../../styles/mixins/container-width-padding-margin.scss';

.Footer {
  @include hint-text;
  @include container-width-padding-margin;

  font-size: 1rem;
  text-decoration: underline;
  padding: 0 $container-padding 5.5rem $container-padding;
  
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0.5rem;
    @include up-md {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &__item {
    padding: 0.5rem 0;
    text-align: center;
  }

  &__link {
    cursor: pointer;
    color: $color-light;
    padding: 0 1rem;
  }

  &__logout-link {

    @include up-md {
      margin-left: auto;
    }
    
    a {
      text-transform: uppercase;
    }
  }
  
}
