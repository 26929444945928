@import '../../styles/variables';
@import '../../styles/mixins/hint-text';
@import '../../styles/mixins/3d-block';

.NavigationBar {

  &__hint {
    @include hint-text;
    font-size: 1rem;
    text-align: center;
    margin-top: 0.15rem;
    font-weight: 500;
    text-transform: uppercase;
  }

}
