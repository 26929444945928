@import '../../styles/mixins/animations';
@import '../../styles/variables';

.Loader {
  $c: &;
  @include transition-all;

  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  // background-color: rgba(255, 255, 255, 0.75);
  background-color: rgba(119, 125, 131, 0.9);
  z-index: 99999;

  opacity: 1;
  pointer-events: all;

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--is-embedded {
    position: initial;
    width: auto;
    height: auto;
    text-align: center;

    #{$c}__content {
      position: initial;
      width: auto;
      height: auto;
      text-align: center;
      transform: initial;
    }
  }

  &__image-wrapper {
    border-radius: 50%;
    background-color: $color-blank;
    padding: 1.5rem;

    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
  } 

  &__image {
    $size: 5rem;
    width: $size;
    height: $size;
  }

  &__text {
    padding-left: 3rem;
    padding-top: 0.25rem;
    font-size: 2rem;
    font-weight: 900;
    color: $color-mixed;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg); transform:rotate(360deg);
    }
  }
}
