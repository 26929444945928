@import '../../styles/variables';
@import '../../styles/mixins/medias';
@import '../../styles/mixins/hint-text';

.ImageHeading {
  &__image {
    $size: 12rem;
    width: $size;
    height: $size;
    background-color: $color-blank;

    border-radius: 50%;

    display: block;
    margin: 2.5rem auto 1.5rem;
  }

  &__title {
    @include hint-text;
    text-align: center;
    color: $color-blank;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  &__subtitle {
    @include hint-text;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2rem;
    
    font-weight: 700;
    color: $color-blank;
  }

  &__color-mixed {
    color: $color-mixed;
  }
}
