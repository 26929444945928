@import '../../../styles/variables';

.NavigationLinksLine {
  padding-top: 0.75rem;
  display: flex;
  justify-content: center;

  &__item {
    border-bottom: 1px solid $color-light;
    padding-bottom: 0.75rem;
    cursor: pointer;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // no blue rectangle on tap for mobile devices.

    button {
      min-width: 6rem;  

      .Button__text {
        padding: .8em 0em;
      }
    }
  }
}
