@import '../../styles/variables';
@import '../../styles/mixins/hint-text';

.SectionTitle {
  @include hint-text;
  color: $color-background-text;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid $color-background-hint-line;
  
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
}
