@import '../variables';
@import './medias';

@mixin container-width-padding-margin {
  margin: 0 auto;
  padding: 0 $container-padding 17.5rem $container-padding;

  @include up-sm {
    width: 750px;
  }
  @include up-md {
    width: 970px;
  }
  @include up-lg {
    width: 1170px;
  }
}
