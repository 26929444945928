@import '../../../styles/variables';
@import '../../../styles/utils/text-noselect';
@import '../../../styles/mixins/animations';
@import '../../../styles/mixins/compensate-border';
@import '../../../styles/mixins/3d-block';

.Button__compensate-border {
  @include compensate-border;
};

.Button {
  $c: &;

  @extend %text-noselect;
  @include block-3D-color;

  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1rem; // takes the size of the plate it stands on
  margin-top: 0;

  z-index: 10; // one more than elevated Plate has

  @include elevate-3D-block;

  &__text {
    @include transition-all;
    
    padding: .8rem 1rem;
    font-weight: 500;
    letter-spacing: 1px;
  }

  &__has-icon {
    $icon-button-size: 12.8px + 12.8px + 17px + 10px;
    height: $icon-button-size;
    width: $icon-button-size;
    padding: 0.95rem;
    // padding: 0.3em;
    // background-color: $color-light !important;
    // background-image: $gradient-face-bottom !important;
  }

  &__icon {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  &:active {
    @include elevate-3D-block-grounded;
    box-shadow: $grounded-block-glow-box-shadow-1;
  }

  &.accent {
    &:active {
      background-image: $gradient-face-bottom;
      color: $color-blank;
      box-shadow: $grounded-block-glow-box-shadow-1;
    }
  }

  &.main {
    &:active {
      background: $color-blank;
      box-shadow: $grounded-block-glow-box-shadow-1;
      
      #{$c}__text {
        color: $color-body;
      }
    }
  }

  &.neutral {

    &:active {
      @include is-neutral-active;
    }
  }

  &__no-pointer-events {
    pointer-events: none;
  }

  &:disabled,
  button[disabled] {
    opacity: 0.85 !important;
    cursor: none !important;
    pointer-events: none !important;
    background-color: transparent !important;
    background-image: none !important;
    border-image: none !important; 
    border: $block-3d-border-disable !important;
    color: $color-blank !important;
    &:before {
      background-image: none !important;
      border-image: none !important;
      background-color: $color-blank;
      border: $block-3d-border-disable !important;
    }
    &:after {
      background-image: none !important;
      border-image: none !important;
      background-color: $color-blank;
      border: $block-3d-border-disable !important;
    }
  }

  &__pressed {
    @include is-neutral-active;
    @include elevate-3D-block-grounded;
    pointer-events: none;
    cursor: none;
  }

  &__hidden {
    @include transition-all;
    opacity: 0;
    pointer-events: none;
    cursor: none;
  }

}
