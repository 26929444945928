@import '../variables';

@mixin block-3D-color {
  position: relative;
  margin-top: 0.5rem;
  z-index: 1;
  
  font-size: 1rem;
  color: $color-body;

  background-color: white;
  border: $block-3d-border-size $color-accent-1 solid;
  border-image: $gradient-face-bottom;
  border-image-slice: 1 1 0 0;

  @include transition-all;
  
  &:before,
  &:after {
    content: '';
    
    display: block;
    position: absolute;
    z-index: -1;

    background-color: white;
    border: $block-3d-border-size $color-accent-1 solid;
  }
  
  // SIDE
  &:before {
    width: $platforms-width;   
    height: calc(100% - .13rem);

    // top: .15em;
    // left: -0.59em;
    top: .15rem;
    left: -0.63rem;

    border-image: $gradient-side;
    border-image-slice: 1 1 0 1;

    transform: skewY(-45deg);
  }
  
  // BOTTOM
  &:after {
    width: calc(100% - .13rem);
    height: $platforms-width;

    // bottom: -0.61em;
    // right: 0.16em;
    
    bottom: -0.61em;
    right: .15em;

    border-image: $gradient-face-bottom;
    // border-image-slice: 1 1 1 0;
    border-image-slice: 1;
    
    transform: skewX(-45deg);
  }

  &.accent {
    border-image-slice: 1;
    &:before {
      top: .15rem;
      left: -0.75rem;
      border-image-slice: 1;
      background-image: $gradient-side;
    }
    &:after {
      bottom: -0.75rem;
      right: .15rem;
      border-image-slice: 1;
      background-image: $gradient-face-bottom;
    }
  }

  &.main {
    background-color: $color-accent-1; // Old browsers
    background-image: $gradient-face-bottom;
    border-image: $gradient-body-border;
    border-image-slice: 1;
    color: $color-blank;
    
    &:before {
      top: .15rem;
      left: -0.75rem;
      border-image-slice: 1;
      background-image: $gradient-side;
    }
    
    &:after {
      bottom: -0.75rem;
      right: .15rem;
      border-image-slice: 1;
      background-image: $gradient-face-bottom;
    }
  }

  &.neutral {
    color: $color-body;
  
    background: none;
    border: $block-3d-border-size solid $color-neutral-dark;
    border-image-slice: 1;
    background-color: white;
  
    &:before,
    &:after {
      background: $color-neutral;
      border: $block-3d-border-size solid $color-neutral;
      border-image-slice: 1;
    }
  
    &:before {
      top: .15rem;
      left: -0.75rem;
    }
    
    &:after {
      bottom: -0.75rem;
      right: .15rem;
    }
  }
}

@mixin elevate-3D-block {
  box-shadow: $elevate-box-shadow !important;
  transform-origin: $elevate-transform-origin;
  transform: $elevate-transform;
}

@mixin elevate-3D-block-grounded {
  box-shadow: $elevate-box-shadow-grounded;
  transform: $elevate-transform-grounded;
}

@mixin is-neutral-active {
  border-image: $gradient-face-bottom !important;
  border-image-slice: 1 !important;
  box-shadow: $grounded-block-glow-box-shadow-1 !important;

  &:before {
    border-image: $gradient-side !important;
    background-image: $gradient-side !important;
  }
  &:after {
    // IMPORTANT! border-image and border-image-slice order is important. border-image first.
    border-image: $gradient-face-bottom !important;
    border-image-slice: 1 !important;
    background-image: $gradient-face-bottom !important;
  }
}
