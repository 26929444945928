// @import url('https://fonts.googleapis.com/css?family=Inconsolata');

html, body, button {
  font-family: "Inconsolata", monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import 'reset';
@import 'container';
@import 'forms';

html, body, button {
  background-color: $color-background;
  letter-spacing: 0.025em;
}

body {
  position: relative;
  min-height: 100vh;
}
