// MEDIAS
$media-min-size-sm: 768px;
$media-min-size-md: 992px;
$media-min-size-lg: 1200px;


// COLORS
$color-blank: white;
$color-hint: rgb(0, 177, 208);
$color-accent-1: rgb(255, 140, 0);
$color-accent-2: rgb(236, 0, 255);
$color-mixed: rgb(244, 60, 120);
$color-body: rgb(0, 15, 18);
$color-neutral: rgb(95, 100, 109);
$color-neutral-dark: rgb(88, 92, 99);

$color-random: rgb(0, 104, 111);

$color-background: rgb(111, 117, 123);
// $color-background-hint-line: rgb(146, 151, 156);
$color-background-hint-line: #abadb1;

// $color-light: rgb(200, 202, 206); // used for hint text.
$color-light: rgb(228, 230, 235); // used for hint text.
// $color-background-text: rgb(227, 227, 227);
$color-background-text: rgb(255, 255, 255);
$color-deviders: rgb(204, 206, 211);

$color-shadow: transparentize($color-body, .6);


// SIZES
$container-padding: 15px;
$platforms-width: 0.31em;

// landing
$landing-paragraph-bottom-space: 1rem;
$landing-sections-padding: 3.5rem;
$tutorial-items-padding: 9rem;


// ANIMATIONS
$animations-duration: 75ms;


// BLOCK
$block-3d-border-size: 0.1875rem; // 3px by default
$block-3d-border-disable: $block-3d-border-size solid $color-blank;
$gradient-body-border: linear-gradient(45deg, lighten($color-accent-1,20%) 0%, lighten($color-accent-2,20%) 100%);
$gradient-side: linear-gradient(45deg, $color-accent-1 0%, adjust-hue($color-accent-1,10%) 100%);
$gradient-face-bottom: linear-gradient(45deg, $color-accent-1 0%, $color-accent-2 100%);

$grounded-block-glow-box-shadow-1: -10px 9px 0.5em 0px transparentize($color-accent-1, .5);
$grounded-block-glow-box-shadow-2: -10px 9px 0.5em 0px transparentize($color-accent-2, .6);

// elevation
$elevate-box-shadow: -1em 1em 0.5em $color-shadow;
$elevate-transform: translate(0.5em,-0.5em);
$elevate-transform-origin: left bottom;
$elevate-box-shadow-grounded: -0.5em .5em transparentize($color-body, 1);
$elevate-transform-grounded: translate(0, 0);


// FLAT OBJECTS
$flat-object-box-shadow: -1em 1em 4em transparentize($color-body, .8);
// $flat-object-box-shadow-1: 0 0 10em 11em transparentize($color-accent-1, .8);
// $flat-object-box-shadow-2: 0 0 10em 11em transparentize($color-accent-2, .8);
$flat-object-box-shadow-1: 0 0 1em 0.1em transparentize($color-accent-1, .1);
$flat-object-box-shadow-2: 0 0 1em 0.1em transparentize($color-accent-2, .1);
