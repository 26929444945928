@import '../variables';

// Small devices ($media-min-size-sm window width and more)
@mixin up-sm {
  @media (min-width: #{$media-min-size-sm}) {
      @content;
  }
}

// Medium devices
@mixin up-md {
  @media (min-width: #{$media-min-size-md}) {
      @content;
  }
}

// Large devices
@mixin up-lg {
  @media (min-width: #{$media-min-size-lg}) {
      @content;
  }
}
